form.mainform {
  width: 500px;
  margin: 20px auto;
  font-family: "Gotham A", "Gotham B";
  display: flex;
  flex-direction: column;
}


div.mainform-head{
  margin: 5px 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  color: #435275;
  transform: translateX(-45px);
}

div.mainform-head svg{
  width: 35px;
  padding: 5px;
}

form.mainform label{
  font-family: "Chronicle Text G1 A", "Chronicle Text G1 B";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 2px;
  color: #435275;
}

form.mainform input{
  font-family: "Gotham A", "Gotham B";
  font-size: 20px;
  font-weight: 400;
  background: #F6FAFF;
  border: 2px solid #ECF2FF;
  outline: none;
  margin-bottom: 20px;
  padding: 5px 8px;
  letter-spacing: .5px;
  box-shadow: inset 1px 1px 3px #d5d6d9,
            inset -1px -1px 3px #ffffff;
  transition: all 60ms ease-in;
}

form.mainform input:focus, form.mainform select:focus{
  font-weight: 500;
  background: #FFFFFF;
  border: 2px solid #002552;
  letter-spacing: 0px;
  box-shadow: inset 2px 2px 4px #d5d6d9,
            inset -2px -2px 4px #ffffff;
}

form.mainform select{
  font-family: "Gotham A", "Gotham B";
  font-size: 20px;
  border: none;
  padding: 5px 8px;
  letter-spacing: .2px;
  outline: none;
  box-shadow: inset 1px 1px 3px #d5d6d9,
            inset -1px -1px 3px #ffffff;
}



div.alertpref{
  width: 500px;
  font-family: "Gotham A", "Gotham B";
  display: flex;
  flex-direction: column;
}

div.mainform-head input[type=checkbox]{
  border: none;
  outline: none;
  letter-spacing: .2px;
  box-shadow: none;
}

form.mainform button[type=submit]{
  width: 200px;
  height: 40px;
  font-family: "Gotham A", "Gotham B";
  font-size: 16px;
  font-weight: 500;
  color: #ECF2FF;
  background-color: #001A52;
  outline: none;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 20px;
  align-self: flex-end;
  cursor: pointer;
}

form.mainform button[type=submit]:hover{
  box-shadow: 1px 1px 4px #1D3569;
}



table.alertsource{
  font-family: "Gotham A", "Gotham B";
  font-style: normal;


}

table.alertsource th{
  font-weight: 500;
  font-size: 18px;

  padding: 15px 0px;
}
table.alertsource td{

  padding: 15px;
  text-align: left;
}
table.alertsource input[type=checkbox]{
  position: relative;
  cursor: pointer;
  transform: scale(2);
  box-shadow: none;

}


label.checkbox{
  padding: 10px;
}


@media only screen and (max-width: 700px) {

  form.mainform {
    width: 90vw;
  }

  div.mainform-head{
    transform: translateX(0px);
  }


  form.mainform label{
    font-size: 16px;
  }

  form.mainform input{
    font-size: 16px;
  }

  form.mainform select{
    font-size: 16px;
  }

  div.alertpref{
    width: 90vw;
  }



}
