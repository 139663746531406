div.portal-main{
  width: 500px;
  margin: 20px auto;
  font-family: "Gotham A", "Gotham B";
  display: flex;
  flex-direction: column;
}


div.portal-status{
  display: flex;
  flex-direction: column;
}

div.portal-main h2{
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  color: #435275;
}



div.portal-table th{
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #435275;

}


div.portal-table td{
  width: 200px;
  padding: 10px 10px 10px 0px;
  font-size: 16px;
}


button.portal-button{
  height: 30px;
  margin-right: 20px;
  font-family: "Gotham A", "Gotham B";
  font-size: 14px;
  padding: 3px 20px;
  font-weight: 500;
  color: #ECF2FF;
  background-color: #001A52;
  outline: none;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 20px;
  align-self: flex-end;
  cursor: pointer;
}

h3.status-detail{
  font-size: 16px;
  font-weight: 400;

}



@media only screen and (max-width: 700px) {

  div.portal-main{
    width: 90vw;
  }




  button.portal-button{
    height: 30px;
    margin: 10px 20px 10px 0px;
  }







}
