div.other{
  padding: 20px;
  max-width: 600px;
}

div.other p{
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}

div.other h1{
  font-size: 24px;
}

div.other h2{
  font-size: 20px;
}
