.demo-signup{
  font-size: 30px;
  color: #001A52;
  font-weight: 600;
  margin: 20px 0px;
  text-transform: uppercase;
}


div.demo input {
  width: 280px;
  height: 50px;
  background: white;
  outline: none;
  border: none;
  padding: 0px 10px;
  color: black;
  margin-top: 10px;
  letter-spacing: 2px;
  border-radius: 8px;
  font-size: 20px;
  color: #001A52;
  font-weight: 500;
}
.demo button[type=submit]{
  margin: 30px 0px;
  width: 200px;
  height: 40px;
  font-family: "Gotham A", "Gotham B";
  font-size: 16px;
  font-weight: 500;
  color: #ECF2FF;
  background-color: #001A52;
  outline: none;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 20px;
  align-self: flex-end;
  cursor: pointer;
}
